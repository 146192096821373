<template>
  <item-wrapper id="warranty">
    <item-header :title="t('warranty.title')" />
    <main>
      <div>
        <p>{{t('warranty.content1')}}</p>
        <p>{{t('warranty.content2')}}</p>
        <h3>{{t('warranty.content3')}}</h3>
        <ul>
          <li>{{t('warranty.list1')}}</li>
          <li>{{t('warranty.list2')}}</li>
          <li>{{t('warranty.list3')}}</li>
        </ul>
        <div class="address">
          <h3>{{t('warranty.p')}}</h3>
          <h4>{{t('warranty.p1')}}</h4>
          <h4>{{t('warranty.p2')}}</h4>
          <h4>{{t('warranty.p3')}}</h4>
        </div>
      </div>
    </main>
  </item-wrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import ItemWrapper from '@/components/footerlink/ItemWrapper.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'WarrantyInformation',
  components: {
    ItemHeader,
    ItemWrapper,
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixin.scss";
@import "@/assets/styles/variables.scss";

:deep(header) {
  h2 {
    margin-bottom: 40px;
  }
}

main {
  @include flex-center();
  flex-direction: column;
  max-width: 1270px;
  margin: 0 auto;
  margin-top:61px;
  div {
    margin-bottom: 20px;
    p {
      font-size: 18px;
      font-weight: 400;
      color: #545454;
      line-height: 35px;
      margin-bottom:37px;
    }
    h3 {
      font-size: 18px;
      font-weight: 400;
      color: #545454;
      line-height: 35px;
    }
    ul{
      // padding-left:0px;
      li{
        font-size: 18px;
        font-weight: 400;
        color: #545454;
        line-height: 35px;
      }
    }
    .address{
      font-size: 18px;
      font-weight: 800;
      color: #1C1C1C;
      line-height: 35px;
      margin-top:20px;
      h3{
        font-weight:bold;
      }
    }
  }
}
</style>
